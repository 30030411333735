// @flow
import React from 'react'
import { graphql } from 'gatsby'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
// import Img from 'gatsby-image'

import Layout, { SEO } from '../components/Layout'

const lightboxOptions = {
  settings: {
    autoplaySpeed: 5000,
  },
  buttons: { showDownloadButton: false, showThumbnailsButton: false },
}

const Template = ({ data }: { data: Object }) => {
  const { markdownRemark } = data
  const {
    frontmatter: {
      title,
      location,
      street,
      shortDescription,
      year,
      hoai,
      images,
    },
    html,
  } = markdownRemark

  return (
    <Layout>
      <SEO title={title} />

      <div className="content-aside">
        <h1>
          <span className="black">{title}</span>
          <br />
          {location}
          {street && (
            <>
              <br />
              {street}
            </>
          )}
        </h1>
      </div>

      <div className="content">
        {images && (
          <SimpleReactLightbox>
            <SRLWrapper options={lightboxOptions}>
              <ul className="images-list">
                {images.map((img, index) => (
                  <li key={`${img}-${index}`}>
                    <a
                      href={img.url}
                      title={img.description}
                      data-attribute="SRL"
                    >
                      <img src={img.url} alt={img.description} />
                    </a>
                  </li>
                ))}
              </ul>
            </SRLWrapper>
          </SimpleReactLightbox>
        )}

        {shortDescription && (
          <p className="shortDescription">{shortDescription}</p>
        )}

        {hoai && <p className="hoai">{hoai}</p>}
        {year && <p className="year">{year}</p>}

        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        location
        street
        shortDescription
        hoai
        year
        slug

        images {
          url
          description
        }
      }
    }
  }
`

export default Template
